/* Gemeinsame Stile */
.konnichen-div {
    text-align: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.big_header {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.function_header {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
}

.function_list {
    list-style-type: none;
    padding: 0;
}

.function-item {
    margin-bottom: 1rem;
}

.function-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
    text-align: left;
}

.function-toggle {
    cursor: pointer;
    padding: 0.5rem;
    display: inline-block;
    vertical-align: middle;
}

.function-box h3 {
    display: inline-block;
    margin: 0;
    padding: 0.5rem;
    font-size: 2em;
    vertical-align: middle;
}



.function-content {
    display: flex;
    padding: 1rem;
}

.function-content img {
    width: 200px;
    height: auto;
    margin-right: 1rem;
    border-radius: 8px;
}

.function-description {
    flex: 1;
    font-size: 1.2rem;
    margin-top: 0.5rem;
}

.closed .function-content {
    display: none;
}

.invite_button {
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    margin-top: 2rem;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.invite_button a {
    text-decoration: none;
}

/* Light Mode Stile */
.light .konnichen-div {
    color: black;
}

.light .big_header {
    color: #333333;
}

.light .description {
    color: #666666;
}

.light .function_header {
    color: #333333;
}

.light .function-box {
    background-color: #f5f5f5;
    border-color: #ccc;
}

.light .function-description {
    color: #666;
}

.light .invite_button {
    background-color: #4CAF50;
    color: white;
}

.light .invite_button:hover {
    background-color: #45a049;
}

.light .invite_button a {
    color: white;
}

.light .function-content img {
    box-shadow: -5px 1px #0B1925;
}

/* Dark Mode Stile */
.dark .konnichen-div {
    background-color: #0B1925;
    color: white;
}

.dark .big_header {
    color: #e0e0e0;
}

.dark .description {
    color: #cfcfcf;
}

.dark .function_header {
    color: #e0e0e0;
}

.dark .function-box {
    background-color: #1a2b3c;
    border-color: #2a3b4c;
}

.dark .function-description {
    color: #aaa;
}

.dark .invite_button {
    background-color: #0B1925;
    color: #e0e0e0;
}

.dark .invite_button:hover {
    background-color: #1a2b3c;
}

.dark .invite_button a {
    color: #e0e0e0;
}

.dark .function-content img {
    box-shadow: -5px 1px #fff;
}
