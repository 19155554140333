/* General styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* App container */
.App {
  min-height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

/* Light mode styles */
.App.light {
  background-color: #f4f4f4;
  color: #333;
}

/* Dark mode styles */
.App.dark {
  background-color: #0B1925;
  color: #f4f4f4;
}

/* Header styles */
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  transition: background-color 0.3s;
  background-color: None;
}

.App-header-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.App-header-title a {
  text-decoration: none;
}

.dark .App-header-title a  {
  color: #f4f4f4;
}

.light .App-header-title a {
  color: #333;
}

.App-header-controls {
  position: relative;
  margin-right: 1.5rem;
}

.App-header-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  display: none;
}


.LangImg {
  width: 50px;
}

.DropdownH2 {
  height: 3rem;
  font-size: 2rem;
}

.DropdownImg {
  height: 3rem;
}

.App-header-controls:hover .App-header-dropdown {
  display: block;
}

.App-header-button {
  border: none;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s, color 0.3s;
}

.App-header-buttons {
  display: flex;
  justify-content: space-between;
}

.light .App-header-button {
  color: #333;
  background-color: #f4f4f4;
}

.dark .App-header-button {
  color: #f4f4f4;
  background-color: #0B1925;
}

.App-header-links {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.App-header-link-heading {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  padding: 10px;
  text-align: center;
}

.App-header-link-dropdown {
  display: flex;
  list-style-type: none;
}

.App-header-link-dropdown-item {
  position: relative;
}

.App-header-link-dropdown-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 10px;
  z-index: 1000; /* Ensures the dropdown appears above other content */
}



/* Remove the hover effect */
.App-header-link-dropdown-item:hover .App-header-link-dropdown-dropdown {
  display: block;
}
.App-header-link-dropdown-dropdown li {
  margin-bottom: 5px;
}

.App-header-link-dropdown-dropdown a {
  text-decoration: none;
  padding: 5px;
}

/* Light Mode */
.light .App-header-link-heading,
.light .App-header-link-dropdown-dropdown a {
  color: #333;
}

.light .App-header-link-heading:hover,
.light .App-header-link-dropdown-dropdown a:hover {
  background-color: #e0e0e0;
}

.light .App-header-link-dropdown-dropdown {
  background-color: #f4f4f4;
  border: 1px #0B1925 solid;
}

/* Dark Mode */
.dark .App-header-link-heading,
.dark .App-header-link-dropdown-dropdown a {
  color: #f4f4f4;
}

.dark .App-header-link-heading:hover,
.dark .App-header-link-dropdown-dropdown a:hover {
  background-color: #1a2b3c; /* Etwas dunklerer Farbton für Hover-Effekt */
}

.dark .App-header-link-dropdown-dropdown {
  background-color: #0B1925;
  border: 1px #fff solid;
}



/* Main content styles */
.App-main {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
}



.App-main-heading {
  text-align: center;
  margin-bottom: 2rem;
}

.App-main-ul {
  list-style-type: none;
}

.App-main-ul-li {
  margin-bottom: 2.5rem;
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.light .App-main-ul-li {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dark .App-main-ul-li {
  background-color: #0B1925;
  border: 1px solid #f4f4f4;
}

.dark .App-main-ul-li:hover {
  background-color: #f4f4f4;
  border: 1px solid #0B1925;
  transition: 0.5s;
}

.dark .App-main-ul-li:hover .App-main-ul-li-text {
  color: #333;
  transition: 0.5s;
}

.light .App-main-ul-li:hover {
  background-color: #0B1925;
  border: 1px solid #f4f4f4;
  transition: 0.5s;
}

.light .App-main-ul-li:hover .App-main-ul-li-text {
  color: #f4f4f4;
  transition: 0.5s;
}

.App-main-ul-li-button {
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.App-main-ul-li-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-grow: 1;
}

.dark .App-main-ul-li-link {
  color: #e0e0e0;
}

.light .App-main-ul-li-link {
  color: #333;
}

.App-main-ul-li-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.App-main-ul-li-text {
  flex-grow: 1;
}

.App-main-ul-li-text h3 {
  margin: 0;
  transition: color 0.3s;
}

/* Dreipunkte-Button */
.App-main-ul-li-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-left: 1rem;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s;
  height: 24px;
  width: 24px;
  padding: 0;
}

.menu-button img {
  width: 100%;
  height: 100%;
}

.light .menu-button {
  color: #444;
}

.dark .menu-button {
  color: #e0e0e0;
}

/* Share Menu Styles */
.share-menu {
  position: fixed;
  scale: 75%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: opacity 0.3s ease;
}

.share-menu-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.share-menu-box h5 {
  text-align: center;
  font-size: 2rem;
}

.share-menu-box h4 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.share-menu-box .link-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: none;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 3px 3px 5px, -3px -3px 5px;
  align-items: center;
}

.share-menu-box .link-info img {
  width: 130%;
  height: 130%;
  max-height: 500px;
}

.share-menu-box .link-info .light{
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: none;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 3px 3px 5px, -3px -3px 5px;
  align-items: center;
}

.share-menu-box .link-info h5 {
  margin: 0 0 0.5rem;
}

.share-menu-box .link-info span {
  font-size: 1.5em;
}

.link-info .name {
  text-align: center;
}

.share-menu-box .link-info img {
  scale: 75%;
}

.share-menu-box a {
  text-decoration: none;
  color: #ccc;
}

.share-menu-box .link-info a {
  text-decoration: none;
  color: #007bff;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.share-menu-box .link-info a:hover {
  text-decoration: underline;
}

.share-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.share-box p {
  margin: 0;
  flex-grow: 1;
}

.share-menu-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}

.share-menu-button:hover {
  background-color: #0056b3;
}

/* Dark mode share menu */
.dark .share-menu-box {
  background-color: #2c2f38;
  color: #f4f4f4;
}

.dark .share-menu-button {
  background-color: #444;
}

.dark .share-menu-button:hover {
  background-color: #333;
}

.dark .share-box p {
  color: #f4f4f4;
}

.dark .share-box img {
  border: 2px solid #f4f4f4;
}

/* Close button */
.share-menu-box .close {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  transition: background-color 0.3s;
}

.share-menu-box button:hover {
  background-color: #d32f2f;
}

main h1 {
  text-align: center;
}


.exP {
  transition: transform 0.5;
  width: 250px;
  border-radius: 100%;
  margin: 1rem;
}

.exp:hover {
  transform: rotate(180deg)
}

.App-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  transition: background-color 0.3s;
}

.App-footer-controls {
  position: relative;
}

.App-footer-dropdown {
  display: none; /* Standardmäßig versteckt */
  position: absolute; /* Positioniert es relativ zum Footer */
  top: 100%; /* Direkt unter dem Button */
  left: 50%; /* Zentriert */
  transform: translateX(-50%); /* Zentriert */
  background-color: inherit; /* Hintergrundfarbe erben */
  border: 1px solid #ccc; /* Rahmen für das Dropdown */
  border-radius: 4px;
  padding: 0.5rem;
  z-index: 1000; /* Sicherstellen, dass es über anderen Inhalten liegt */
}

.App-footer-controls:hover .App-footer-dropdown,
.App-footer-controls .App-footer-dropdown.show {
  display: flex; /* Anzeigen bei Hover oder wenn die Klasse 'show' hinzugefügt wird */
}



.legal-button {
  margin: 1rem;
  background-color: transparent;
  border: none;
}

.legal-button a {
  text-decoration: none;
}

.light .legal-button a {
  color: #333; /* Farbe für Light Mode */
}

.dark .legal-button a {
  color: #e0e0e0; /* Farbe für Dark Mode */
}

.light .App-footer button,
.light .App-footer-button {
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  border-radius: 3px;
  padding: 1em;
}

.dark .App-footer button,
.dark .App-footer-button {
  background-color: transparent;
  color: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 1em;
}


.App-header-link-dropdown-dropdown {
  display: block; /* Immer sichtbar */
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 10px;
  z-index: 1000;
}

.App-footer-controls {
  display: flex; /* Flexbox für die Buttons */
  justify-content: center; /* Zentriert die Buttons */
}

.legal-button {
  margin: 0.5rem; /* Abstand zwischen den Buttons */
}
