/* styles.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .App {
    text-align: center;
  }
  
  .impress {
    padding: 20px;
  }

  .light {
    --background-color: #f4f4f4;
    --text-color: #333;
  }
  
  .dark {
    --background-color: #0B1925;
    --text-color: #e0e0e0;
  }
  
  body.light, .App.light {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  body.dark, .App.dark {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button.light {
    background-color: #ddd;
  }
  
  button.dark {
    background-color: #444;
  }
  
  /* In Ihrer impressum.css */
.email-link {
    color: #007bff;  /* Freundliches Blau */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .email-link:hover {
    color: #0056b3;  /* Dunkleres Blau beim Hover */
    text-decoration: underline;
  }
  
  /* Für Dark Mode Variante */
  .dark .email-link {
    color: #4da6ff;  /* Helleres Blau für Dark Mode */
  }
  
  .dark .email-link:hover {
    color: #80bcff;  /* Noch helleres Blau */
  }
  
