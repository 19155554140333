/* Gemeinsame Stile */
.bible-div {
    text-align: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.big_header {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.function_header {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.function_list {
    list-style-type: none;
    padding: 0;
}

.function_list li {
    display: flex;
    align-items: center; /* Vertikale Ausrichtung */
    margin-bottom: 3rem;
    gap: 3rem; /* Abstand zwischen Bild und Text */
}

.function_list img {
    width: 200px; /* Bildgröße */
    height: auto;
}

.function_list p {
    flex: 1; /* Nimmt den verfügbaren Platz ein */
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

/* Flex-Richtungen für die Anordnung */
.left_function {
    flex-direction: row; /* Bild links, Text rechts */
}

.right_function {
    flex-direction: row-reverse; /* Bild rechts, Text links */
}


.visit_button {
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    margin-top: 2rem;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.visit_button a {
    text-decoration: none;
}

/* Light Mode Stile */
.light .konnichen-div {

    color: black;
}

.light .big_header {
    color: #333333;
}

.light .description {
    color: #666666;
}

.light .function_header {
    color: #333333;
}

.light .function_list li {
    border-color: #ccc;
}

.light .visit_button {
    background-color: #4CAF50;
    color: white;
}

.light .visit_button:hover {
    background-color: #45a049;
}

.light .visit_button a {
    color: white;
}

/* Dark Mode Stile */
.dark .konnichen-div {
    background-color: #0B1925;
    color: white;
}

.dark .big_header {
    color: #e0e0e0;
}

.dark .description {
    color: #cfcfcf;
}

.dark .function_header {
    color: #e0e0e0;
}

.dark .function_list li {
    border-color: #0B1925;
}

.dark .visit_button {
    background-color: #0B1925;
    color: #e0e0e0;
}

.dark .visit_button:hover {
    background-color: #1a2b3c; /* Etwas dunklerer Farbton für Hover-Effekt */
}

.dark .visit_button a {
    color: #e0e0e0;
}
